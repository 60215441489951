import React from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'
import Mainview from 'src/components/mainview/index'
import Title from 'src/components/title/index'
import Inner from 'src/components/inner/index'
import Image from 'src/components/image/index'
import Link from 'src/components/link/index'
import Information from 'src/components/information/index'
import Features from 'src/components/features/index'
import Slider from 'src/components/news/slider'
import Facility from 'src/components/facility/index'
import I_AM_THE_FIT from 'src/components/iatf/index'
import GoogleMap from 'src/components/googlemap/index'
import ReadyToGo from 'src/components/readytogo/index'

import styles from '../index.module.scss'

const GymPage = () => {
	return (
		<div>
			<SEO
				title="都立大学駅前店"
				description="WE ARE THE FITは都立大学駅から徒歩1分にある24時間営業のフィットネスジムです。本格マシーンを多数取り揃え、パーソナルトレーニング、ボディメイクプランも承っています。初心者向けエリアもご用意しておりますので、まずはお気軽にご見学にお越しください。"
			/>
			<Header hasBack={false} />

			<Mainview className={styles.mainView}>
				<div className={styles.mainBody}>
					<div className={styles.mainImage}>
						<Image src="/gyms/toritsudai/mainview.jpg" />
					</div>
					<div className={styles.mainTitle}>
						<span>WE ARE THE FIT</span>
						<h1>TORITSUDAI</h1>
					</div>
				</div>
				<Information gym="toritsudai" />
			</Mainview>

			<section className={styles.news}>
				<Title
					h2="NEWS"
					right={
						<Link className={styles.newsLink} to="/news/">
							View All News
						</Link>
					}
				/>
				<div className={styles.newsList}>
					<Slider filter="toritsudai" />
				</div>
				<div className={styles.newsAll}>
					<Link to="/news/">View All News</Link>
				</div>
			</section>

			<section className={styles.features}>
				<Title
					h2="FEATURE"
					sub={
						<div className={styles.subtitle}>
							ワークアウトが初めての方でも扱いやすいマシンを集めたフロアと上級者も満足いくマシンを揃えたフロア。それそれ適した環境を備えた空間は、これまでの24時間ジムでは得られなかった体験をお届けします。
						</div>
					}
				/>
				<div className={styles.featuresList}>
					<Features
						items={[
							{
								title: `特徴１：初心者向けフロア`,
								text: `フィットネス初心者でも、ご安心ください。<br />WE ARE THE FITは初心者向けフロアをご用意しておりますので、周りに気兼ねなく安心してご利用いただけます。<br />スタッフによる施設案内・マシーンの使い方の説明・カウンセリング・トレーニングサポートプランを無料で実施。1対1のサポートですので、初めての方でもご心配なく。<br />初回でなくても、不明点・ご質問などあればいつでもスタッフにお声がけください。`,
								image: `/gyms/toritsudai/feature_01.jpg`,
							},
							{
								title: `特徴２：上級者向けフロア`,
								text: `最新マシーンを揃えた上級者フロアで、本格的なトレーニングを。<br />トレーニングに慣れてきた方、本格的なボディーメイクのための最新マシーンを揃えた上級者フロアを用意しています。特にフリーウェイトマシンの種類の豊富さには自信があります。ダンベルも最高50kgまでご用意。各種アクセサリーも豊富にご用意しております。`,
								image: `/gyms/toritsudai/feature_02.jpg`,
							},
							{
								title: `特徴３：24時間利用可能`,
								text: `WE ARE THE FITは、24時間営業のフィットネスクラブです。<br />スタッフの常駐していない早朝・深夜帯でも、セキュリティカードをかざすだけでいつでも施設を利用できます。しっかりセキュリティ対策もされているので女性の方の深夜帯利用も安心です。`,
								image: `/gyms/toritsudai/feature_03.jpg`,
							},
							{
								title: `特徴４：本格マシーン多数設置`,
								text: `世界標準の最新マシーンを多数取り揃えました。かなり自信があります。本格的なマシーンを使ったトレーニングで理想のカラダ作りを。効果的なトレーニングに欠かせないアクセサリーの種類も豊富にご用意。`,
								image: `/gyms/toritsudai/feature_04.jpg`,
							},
							{
								title: `特徴５：トレーニングのサポート`,
								text: `WE ARE THE FITにはトレーニングに精通したスタッフが常駐しております（スタッフ在中時間帯）。マシンの使い方を紹介したり、トレーニングアドバイスなどご質問にあわせてサポートいたします。ご入会時にはビギナーズサポートとして無料のサポートプランをご用意。1対1での丁寧なサポートでトレーニングが初めての方にも続けやすい環境を準備してお待ちしております。`,
								image: `/gyms/toritsudai/feature_05.jpg`,
							},
						]}
					/>
				</div>
				<div className={styles.services}>
					<Inner>
						<I_AM_THE_FIT />
					</Inner>
				</div>
			</section>

			<section className={styles.facility}>
				<Title
					h2="FACILITIES"
					sub={
						<div className={styles.subtitle}>
							トレーニングに慣れている方、初級フロアでは物足りなくなった方向けに、最新マシーンを揃えた上級者フロアを用意しています。特にフリーウェイトマシンの種類の豊富さには自信があります。ダンベルも最高50kgまでご用意。
						</div>
					}
				/>
				<Facility
					items={[
						{
							image: `/gyms/toritsudai/facility_01.jpg`,
							caption: `初心者向けフロアは天井も高く、圧迫感の無い開放的な雰囲気でトレーニングが行える環境をご用意。`,
						},
						{
							image: `/gyms/toritsudai/facility_02.jpg`,
							caption: `室内でいつでも走れるランニングマシン。台数も多く、TV、インターネットも見れる最新機種になります。`,
						},
						{
							image: `/gyms/toritsudai/facility_03.jpg`,
							caption: `マシンと通路の間隔も広いので、動作の大きいマシンも安心してご利用頂けます。`,
						},
						{
							image: `/gyms/toritsudai/facility_04.jpg`,
							caption: `ストレッチエリアには鏡も設置。腹筋ローラーやストレッチポールなどアクセサリも豊富に取り揃えております。`,
						},
						{
							image: `/gyms/toritsudai/facility_05.jpg`,
							caption: `圧迫感のない空間と豊富なマシン、そして力強い言葉とスタッフのサポートはあなたの心と身体に素晴らしい変化をもたらしてくれるはずです。`,
						},
						{
							image: `/gyms/toritsudai/facility_06.jpg`,
							caption: `フリーウェイトの代名詞、パワーラックエリートは3台ございます。様々なトレーニング種目を行える環境をご用意いたしました。`,
						},
						{
							image: `/gyms/toritsudai/facility_07.jpg`,
							caption: `自らの身体に向き合う、そんな上級者向けの空間をご用意。トレーニングに集中できる環境で、自身の身体との「会話」をお楽しみください。`,
						},
						{
							image: `/gyms/toritsudai/facility_08.jpg`,
							caption: `本格的なフリーウェイトマシンを多数設置。鍛えたい部位に合ったマシンときっと出会えるはずです。`,
						},
						{
							image: `/gyms/toritsudai/facility_09.jpg`,
							caption: `壁面にはしっかり鏡が設置されているのでフォーム確認も安心です。`,
						},
						{
							image: `/gyms/toritsudai/facility_10.jpg`,
							caption: `ダンベルは50kgまでご用意。ダンベルマットもございますので、高重量のトレーニングも心置きなくお楽しみいただけます。`,
						},
					]}
				/>
			</section>

			<section className={styles.access}>
				<Inner>
					<div className={styles.accessGrid}>
						<div className={styles.accessTitle}>
							<h2>ACCESS</h2>
						</div>
						<div className={styles.accessData}>
							<div className={styles.accessName}>WE ARE THE FIT TORITSUDAI</div>
							<div className={styles.accessAddress}>〒152-0031 東京都目黒区中根1丁目3-11</div>
							<div className={styles.accessTel}>TEL：03-5726-8065</div>
							<div className={styles.accessOther}>
								東急東横線 都立大学駅から徒歩30秒
								<br />
								24 時間営業<small>（スタッフ常駐時間 11:00～15:00、16:00～20:00）</small>
							</div>
						</div>
						<div className={styles.accessMap}>
							<div className={styles.accessPlacer}>
								<div>
									<GoogleMap center={{ lat: 35.61755558415979, lng: 139.67549921678236 }} />
								</div>
							</div>
						</div>
					</div>
				</Inner>
			</section>

			<ReadyToGo />
		</div>
	)
}

export default GymPage
